import { q, ww } from 'bobjoll/ts/library/dom';
import { isAdBlockEnabled } from 'CommonFI/adBlock';
import User from 'Project/ts/pods/user/main';

export default class Ads {
    private static retry = 0;
    private static retryMax = 5;
    private static retryMaxOnForce = 12;
    private static template = require('Project/ts/templates/parts/ads.hbs');
    private static minHeight = 300;
    private static adsMinHeigthDetail = 155;
    private static totalSpacesToRender: number;
    private static renderedSpaces = 0;

    private static insert(id: string, selector: string, method: string, argument: string): HTMLElement | null {
        const element: any = q(selector);
        if (element) {
            element[method](
                argument,
                Ads.template({
                    id: id,
                }),
            );
        }
        return document.getElementById(id);
    }
    private static slotRender(ad: AdsBannerData) {
        if(ww.FeatureFlag.isEnabled('enable_google_ads_detail')) {
            if (User.isPremium() || ww.isMobile ) return;
            if (ww.view.section === 'icon-search' && RESOURCE_TYPE !== 'standard') return;
        }  else {
            if (User.isPremium() || ww.isMobile || RESOURCE_TYPE != 'standard') return;
        }

        window.addEventListener('resize', function() {
            let bnElements = document.querySelectorAll('.bn-space');
            bnElements.forEach(element => {
                Ads.renderInPosition(element as HTMLElement);
            });
        });
        if (ad.container) {
            let space = document.getElementById(ad.container);
            if (space) {
                Ads.renderInPosition(space);
                if (isAdBlockEnabled()) {
                    setTimeout(() => Ads.pushOwnAds(), 10);
                }
            }
        }
        const searchFiltersInput = document.getElementById('search__filters');
        if (searchFiltersInput) {
            searchFiltersInput.addEventListener('change', function() {
                let bnElements = document.querySelectorAll('.bn-space');
                setTimeout(() => {
                    bnElements.forEach(element => {
                        Ads.renderInPosition(element as HTMLElement);
                    });
                }, 150);
            });
        }
    }
    private static slotPreRender(settings: SettingsPushAds) {
        settings.ads.forEach(ad => {
            let element = document.getElementById(ad.id);
            if (!element && settings.insert) {
                element = Ads.insert(ad.id, settings.insert.selector, settings.insert.method, settings.insert.argument);
            }
        });
    }

    private static pushStandard(settings: SettingsPushAds) {
        Ads.retry = 0;
        settings.ads.forEach(ad => Ads.slotRender(ad));
        if (this.renderedSpaces == this.totalSpacesToRender) {
            ww.view.showResults();
        }
    }

    public static slotRenderEnded(slot: any) {
        const slotElement = document.getElementById(slot.getSlotElementId() || '');
        if (slotElement) {
            const sponsorElement = slotElement.parent('.spr');
            const showcaseElement = slotElement.parent('.showcase');
            if (sponsorElement) {
                sponsorElement.classList.remove('hide');
            }
            if (showcaseElement) {
                showcaseElement.dispatchEvent(new Event('resize'));
            }
        }
    }
    public static push(settings: SettingsPushAds) {
        if (User.isPremium() || (ww.isMobile && !settings.allowMobile)) {
            ww.view.showResults();
            return;
        }

        Ads.slotPreRender(settings);
        this.totalSpacesToRender = settings.ads.length;
        if (this.isGoogleTagActive() && !isAdBlockEnabled()) {
            Ads.retry++;
            if (Ads.retry <= (settings.force ? Ads.retryMaxOnForce : Ads.retryMax)) {
                setTimeout(() => Ads.push(settings), 300);
            } else {
                Ads.retry = 0;
                Ads.pushStandard(settings);
                Ads.pushOwnAds();
            }
            return;
        }
        Ads.pushStandard(settings);
    }

    private static getRandomAutoPromo() {
        const banner = document.querySelector('ul #bn-icon-list-second') as HTMLElement;

        if (banner) {
            banner.classList.add('sp-slidesgo-container');
            ga('send', 'event', 'campaign', 'view-banner-web', 'autopromo-slides-go', { nonInteraction: true });
            import(/* webpackChunkName: "slides-go" */ 'Partials/slidesgo-banner').then(slidesGo => {
                slidesGo.default.getBanner(banner);
            });
        }
    }

    private static getPromoPremium() {
        var bannerIcon = document.querySelector('ul #bn-icon-list');
        if (bannerIcon) {
            var premiumAds = document.getElementById('fi-premium-edge-content-premium');

            if (premiumAds) {
                bannerIcon.innerHTML = premiumAds.innerHTML;
            }
        }
    }

    private static isEmptyBannerContainer = () => {
        const bannerContainer = document.getElementById('bn-icon-list');
        return bannerContainer?.innerHTML === '';
    };

    private static cleanBannerContainer = () => {
        const bannerContainer = document.getElementById('bn-icon-list');
        return bannerContainer && (bannerContainer.innerHTML = '');
    };

    public static pushOwnAds() {
        if (!this.isEmptyBannerContainer()) {
            this.cleanBannerContainer();
        }

        Ads.getPromoPremium();
        Ads.getRandomAutoPromo();
    }

    private static renderInPosition(space: HTMLElement) {
        const totalIcons = document.querySelectorAll(
            'ul.icons li.icon--item:not(.icon-fill):not(.icon-upload):not(.collectionIcon)',
        ).length;
        const iconsPerRow = Ads.calculateIconsInRow();
        const iconWithMarginIndex = Ads.itemPosition(space, totalIcons, iconsPerRow);
        const iconWithMargin = document.querySelectorAll(
            'ul.icons li.icon--item:not(.icon-fill):not(.icon-upload):not(.collectionIcon)',
        )[iconWithMarginIndex - 1] as HTMLDivElement;
        if (iconWithMargin && space) {
            iconWithMargin.insertAdjacentElement('afterend', space);
            space.setAttribute(
                'style',
                `height:${Ads.minHeight}px; visibility: visible; display: block !important; position:relative`,
            );
        }
        this.renderedSpaces++;
    }

    private static itemPosition(space: HTMLElement, totalIcons: number, iconsPerRow: number): number {
        let minElements = space.getAttribute('data-min');
        if (minElements) {
            if (totalIcons >= parseInt(minElements)) {
                let rowNumber = ww.isMobile
                    ? !space.hasAttribute('data-rowm')
                        ? parseInt(space.getAttribute('data-row') || '0')
                        : parseInt(space.getAttribute('data-rowm') || '0')
                    : parseInt(space.getAttribute('data-row') || '0');
                return iconsPerRow * rowNumber;
            }
        }

        return -1;
    }

    private static calculateIconsInRow() {
        let iconsInRow = 1;
        let iconsList = document.querySelectorAll(
            'ul.icons li.icon--item:not(.icon-fill):not(.icon-upload):not(.collectionIcon)',
        );
        let totalIcons = iconsList.length;
        for (var i = 1; i < totalIcons; i++) {
            if (i > 0) {
                if (iconsList[i].getBoundingClientRect().top != iconsList[i - 1].getBoundingClientRect().top) break;
                iconsInRow++;
            }
        }
        return iconsInRow;
    }

    private static isGoogleTagActive = (): boolean =>
        (!ww.googletag || (ww.googletag && (!ww.googletag.display || !ww.googletag.destroySlots))) && document.readyState !== 'complete';
}

interface AdsBannerData {
    id: string;
    list: string;
    target?: { [name: string]: string | number };
    container?: string;
}

interface SettingsPushAds {
    ads: AdsBannerData[];
    insert?: {
        selector: string;
        method: 'insertAdjacentHTML' | 'insertAdjacentElement';
        argument: 'beforebegin' | 'afterbegin' | 'beforeend' | 'afterend';
    };
    force?: boolean;
    allowMobile?: boolean;
}
